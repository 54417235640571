import React from 'react'
import Layout from '../../../components/Default/Layout'
import ServiceHeader from '../../../components/Services/ServiceOverview/ServiceHeader'
import '../../../components/ParagraphUnderPhoto/styles.css'
import Index from '../../../../content/accordion/sluzby'

import { graphql } from 'gatsby'
import {
  SingleAccordion,
  Description,
} from '../../../components/Services/ServiceOverview/SingleAccordion'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb/components'

export const FiremniPoradenstvi = ({ data: { page }, pageContext }) => {
  const { frontmatter } = page
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <>
      <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
        metaCrumb={crumbs}
      >
        <ServiceHeader
          title={frontmatter.title}
          backgroundImage={
            !!frontmatter.image.childImageSharp
              ? frontmatter.image.childImageSharp.fluid.src
              : frontmatter.image
          }
        />
        <Breadcrumb crumbs={crumbs} crumbLabel={frontmatter.title} />
        <div className="Paragraph--Wrapper container">
          <p>
            Pouštíte se do přeměny společnosti, likvidace či restrukturalizace?
            Řešte změny včas s našimi profesionály. Budete tak mít jistotu, že
            vše proběhne účetně správně, v mezích zákona a s minimálními
            daňovými dopady. Dále se specializujeme na nasazení ERP systémů a
            vyhledávání i testování kandidátů na ekonomické pozice.
          </p>
          <p>
            Pomůžeme vám navést firmu správným směrem. Soustředíme se na správné
            nastavení procesů, účetních postupů, probereme možné daňové důsledky
            a společně zvolíme to nejlepší řešení. Kromě daňového a účetního
            poradenství nabízíme také konzultace při výběru ERP systému a pomoc
            při jeho nasazení. Dále jsme ideálním rádcem při vyhledávání
            zaměstnanců na ekonomické pozice. Nenechte nic náhodě a mějte firmu
            pod kontrolou.
          </p>
          <SingleAccordion
            items={Index.firemniPoradenstvi.links.map(item => ({
              title: item.name,
              description: <Description {...item} />,
            }))}
          />
        </div>
      </Layout>
    </>
  )
}
export default FiremniPoradenstvi

export const pageQuery = graphql`
  query FiremniPoradenstvi {
    page: markdownRemark(
      frontmatter: { slug: { eq: "sluzby/firemni-poradenstvi" } }
    ) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
